export const useParseURL = (str: string) => {
  const schema = ref<URL>()
  const isValid = ref(true)

  try {
    schema.value = new URL(str)
  } catch (e) {
    isValid.value = false
  }

  return {
    schema: schema as Ref<URL>,
    isValid,
  }
}
