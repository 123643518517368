import { z } from 'zod'
import type { BoardMember, WorkspaceMember } from './types'
import { PROVIDER } from './constant'

export const validatePasswordSchema = z.string()
export const validateEmailSchema = z
  .string()
  .trim()
  .email('Invalid email address')

export const authFields = reactive([
  {
    name: 'email',
    type: 'text',
    label: 'Email',
    placeholder: 'Enter your email',
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    placeholder: 'Enter your password',
  },
])

export const authProviders = [
  {
    label: 'Continue with Google',
    icon: 'logos:google-icon',
    color: 'gray' as const,
    click: () => {
      handleLoginWithProvider(PROVIDER.GOOGLE)
    },
  },
]

const handleLoginWithProvider = (provider: string) => {
  const {
    public: { apiBaseUrl },
  } = useRuntimeConfig()
  const { query } = useRoute()
  const { host } = useRequestURL()

  let next = ''
  if (query.redirect) {
    const { schema, isValid } = useParseURL(query.redirect as string)
    if (isValid && schema.value.host === host) {
      next = schema.value.href
    }
  }

  if (query.type && query.token) {
    useLocalStorage(
      'invite-return-url',
      `/invite?type=${query.type}&token=${query.token}${
        query.email ? '&email=' + encodeURIComponent(query.email as string) : ''
      }`
    )
  }

  window.location.href =
    `${apiBaseUrl}/social-login/${provider}` + (next ? `?next=${next}` : '')
}

export const profileBaseUrl = (workspaceHandle: string) => {
  return `/w/${workspaceHandle}/u/profile`
}

export const workspaceMemberLabel = (
  workspaceMember: WorkspaceMember | null
) => {
  return workspaceMember?.user?.fullName
    ? workspaceMember?.user?.fullName
    : workspaceMember?.invitationEmail
}

export const boardMemberLabel = (boardMember: BoardMember | null) => {
  if (boardMember?.user?.id) return boardMember?.user?.fullName

  if (boardMember?.invitationWorkspaceMember?.id)
    return boardMember?.invitationWorkspaceMember?.invitationEmail

  return 'Unknown'
}

export const getInvitationLink = (type: string, token: string) => {
  const {
    public: { appBaseUrl },
  } = useRuntimeConfig()
  return appBaseUrl + `/invite?type=${type}&token=${token}`
}

export const parseDomains = (domains: string) => {
  return domains.split('\n').filter((line) => line.trim() !== '')
}

export const formatDomains = (domains: string) => {
  return parseDomains(domains).join(', ')
}
